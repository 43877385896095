import appHeader from "@/components/Header/header.vue";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";
import successiveQuestions from "@/components/successiveQuestions/successiveQuestions.vue";
import { mapGetters } from "vuex";
import chapter2 from "@/json/chapter2.json";

export default {
  name: "chapterTwo",
  components: {
    appHeader,
    plainBtn,
    successiveQuestions
  },
  props: {
    wordIndex: {
      default: 1
    },
    showSynthesis: {
      default: false
    }
  },
  data() {
    return {
      json: chapter2,
      isLoaded: false
    };
  },
  async mounted() {
    const numberOfSteps = await this.$store.dispatch(
      "initSuccessiveExercice",
      chapter2.properties
    );

    if (numberOfSteps !== null && this.parsedWordIndex <= numberOfSteps) {
      await this.$store.dispatch(
        "setSuccessiveActualStep",
        this.parsedWordIndex - 1
      );
      this.isLoaded = true;
    }
  },
  computed: {
    // parsed word index
    // use this instead of props wordIndex
    parsedWordIndex() {
      return parseInt(this.wordIndex);
    },
    castedShowSynthesis() {
      return this.showSynthesis === "true";
    },
    goodAnswers() {
      return this.actualSuccessiveStep
        ? this.actualSuccessiveStep["good-answer"]
        : null;
    },
    stepPictures() {
      return this.actualSuccessiveStep
        ? this.actualSuccessiveStep.pictures
        : [];
    },
    stepSynthesis() {
      return this.actualSuccessiveStep
        ? this.actualSuccessiveStep.synthesisText
        : null;
    },
    progress() {
      if (this.parsedWordIndex) {
        if (!this.isLastSynthesis) {
          return ((this.parsedWordIndex - 1) * 100) / this.numberOfSteps;
        }
      }
      return 100;
    },
    isLastSection() {
      return this.parsedWordIndex === this.numberOfSteps;
    },
    isLastSynthesis() {
      return this.isLastSection && this.castedShowSynthesis;
    },
    ...mapGetters([
      "availableSuccessiveResponses",
      "actualSuccessiveStep",
      "numberOfSuccessiveSteps",
      "numberOfSteps"
    ])
  },
  methods: {
    async goToSynthesis() {
      this.$router.push({
        name: "Chapter-two",
        query: { q: this.parsedWordIndex, s: "true" },
        replace: true
      });
    },
    nextSection() {
      if (this.isLastSection) {
        this.$router.push({
          name: "Home"
        });
      } else {
        this.$router.push({
          name: "Chapter-two",
          query: { q: this.parsedWordIndex + 1 },
          append: true
        });
      }
    }
  },
  watch: {
    async wordIndex(actual) {
      if (this.numberOfSteps !== null && actual <= this.numberOfSteps) {
        await this.$store.dispatch("setSuccessiveActualStep", actual - 1);
      }
    }
  }
};
